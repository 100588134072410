import api from '@/services/api'

export default {
  getAll(page, term, company, cart) {
    return api.get(`/products?page=${page}&term=${term}&company=${company}&cart=${cart}`)
  },

  getPrices(page, company, customer) {
    return api.get(`/products/prices?page=${page}&companyId=${company}&customerId=${customer}`)
  },

  getStock(page, term = '', company) {
    return api.get(`/products/stock?page=${page}&term=${term}&company=${company}`)
  },

  exportPrices(company, customer) {
    return api({
      url: `/products/prices?export=true&companyId=${company}&customerId=${customer}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  exportStock(term, company) {
    return api({
      url: `/products/stock?export=true&term=${term}&company=${company}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  uploadBanner(formData) {
    return api.post('/banners', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadWebBanner(formData) {
    return api.post('/banners/web', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getBanners() {
    return api.get('/banners/')
  },

  getBannersWeb() {
    return api.get('/banners?web=true')
  },

  removeBanner(id) {
    return api.delete(`/banners/${id}`)
  },

  updateBanner(data) {
    return api.put(`/banners/${data._id}`, data)
  },

  sortBanners(data) {
    return api.put(`/banners/sort`, data)
  },

  getDepartments() {
    return api.get('/products/b2b/departments')
  },

  getUniqueProducts(term = '') {
    return api.get(`/products/b2b/unique?term=${term}`)
  },

  addProductImage(formData, code) {
    return api.post(`/products/b2b/images/${code}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  updateImages(code, images) {
    return api.put(`/products/b2b/images/${code}`, { images })
  },

  updateProductLimitRule(data) {
    return api.put(`/product-limit-rule/${data._id}`, data)
  },

  getProductLimitRuleB2b(term = '') {
    return api.get(`/product-limit-rule?origin=b2b&term=${term}`)
  },

  getProductLimitRuleMobwit(term = '') {
    return api.get(`/product-limit-rule?origin=mobwit&term=${term}`)
  },

  createProductLimitRule(data) {
    return api.post('/product-limit-rule', data)
  },

  removeProductLimitRule(id) {
    return api.delete(`/product-limit-rule/${id}`)
  },

  updateDepartmentLimitRule(data) {
    return api.put(`/department-limit-rule/${data._id}`, data)
  },

  getDepartmentLimitRuleB2b(term = '') {
    return api.get(`/department-limit-rule?origin=b2b&term=${term}`)
  },

  getDepartmentLimitRuleMobwit(term = '') {
    return api.get(`/department-limit-rule?origin=mobwit&term=${term}`)
  },

  createDepartmentLimitRule(data) {
    return api.post('/department-limit-rule', data)
  },

  removeDepartmentLimitRule(id) {
    return api.delete(`/department-limit-rule/${id}`)
  },

  updateProductQuantityDiscount(data) {
    return api.put(`/product-quantity-discount/${data._id}`, data)
  },

  getProductQuantityDiscountB2b(term = '', page) {
    return api.get(`/product-quantity-discount?origin=b2b&term=${term}&page=${page}`)
  },

  exportProductQuantityDiscountB2b(term = '') {
    let params = '?origin=b2b&export=true';
    if (term) {
      params += `&term=${term}`;
    }

    return api({
      url: `/product-quantity-discount${params}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  exportProductQuantityDiscountMobwit(term = '') {
    let params = '?origin=mobwit&export=true';
    if (term) {
      params += `&term=${term}`;
    }

    return api({
      url: `/product-quantity-discount${params}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  getProductQuantityDiscountMobwit(term = '', page) {
    return api.get(`/product-quantity-discount?origin=mobwit&term=${term}&page=${page}`)
  },

  createProductQuantityDiscount(data) {
    return api.post('/product-quantity-discount', data)
  },

  removeProductQuantityDiscount(id) {
    return api.delete(`/product-quantity-discount/${id}`)
  },
}